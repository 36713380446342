.serverwahl-slider-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 100;
  position: absolute;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  background-color: var(--dl-color-primary-bg);
}
.serverwahl-slider-container01 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
}
.serverwahl-slider-sliderpanel {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.serverwahl-slider-container02 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.serverwahl-slider-storyfortschrit {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.serverwahl-slider-activ {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-gruen);
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-passiv {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv01 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv02 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv03 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv04 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-larrow {
  flex: 0 0 auto;
  left: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-rarrow {
  flex: 0 0 auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-lottie-node {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  display: none;
  align-self: center;
}
.serverwahl-slider-container03 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.serverwahl-slider-container04 {
  width: 100%;
  display: flex;
  margin-top: 0px;
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  flex-direction: row;
  justify-content: flex-start;
}
.serverwahl-slider-container05 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.serverwahl-slider-image {
  width: 100%;
  height: 100%;
}
.serverwahl-slider-text01 {
  width: auto;
  margin-top: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-twounits);
}
.serverwahl-slider-container06 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: flex-start;
}
.serverwahl-slider-container07 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.serverwahl-slider-image1 {
  width: 100%;
  height: 100%;
}
.serverwahl-slider-text02 {
  width: auto;
  margin-top: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-twounits);
}
.serverwahl-slider-container08 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: flex-start;
}
.serverwahl-slider-container09 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.serverwahl-slider-image2 {
  width: 100%;
  height: 100%;
}
.serverwahl-slider-text03 {
  width: auto;
  margin-top: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-twounits);
}
.serverwahl-slider-container10 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.serverwahl-slider-storyfortschrit1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.serverwahl-slider-passiv05 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-activ1 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-primary-gruen);
}
.serverwahl-slider-passiv06 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv07 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv08 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv09 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-larrow1 {
  flex: 0 0 auto;
  left: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-rarrow1 {
  flex: 0 0 auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-container11 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.serverwahl-slider-container12 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-container13 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-image3 {
  width: 100%;
  height: 100%;
}
.serverwahl-slider-text05 {
  width: auto;
  margin-top: var(--dl-space-space-unit);
}
.serverwahl-slider-container14 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.serverwahl-slider-storyfortschrit2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.serverwahl-slider-passiv10 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-passiv11 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-activ2 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-primary-gruen);
}
.serverwahl-slider-passiv12 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv13 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv14 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-larrow2 {
  flex: 0 0 auto;
  left: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-rarrow2 {
  flex: 0 0 auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-container15 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.serverwahl-slider-container16 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-container17 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-image4 {
  width: 100%;
  height: 100%;
}
.serverwahl-slider-text07 {
  width: auto;
  margin-top: var(--dl-space-space-unit);
}
.serverwahl-slider-container18 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.serverwahl-slider-storyfortschrit3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.serverwahl-slider-passiv15 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-passiv16 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv17 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-activ3 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-primary-gruen);
}
.serverwahl-slider-passiv18 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv19 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-larrow3 {
  flex: 0 0 auto;
  left: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-rarrow3 {
  flex: 0 0 auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-container19 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.serverwahl-slider-container20 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-container21 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-image5 {
  width: 100%;
  height: 100%;
}
.serverwahl-slider-text09 {
  width: auto;
  margin-top: var(--dl-space-space-unit);
}
.serverwahl-slider-container22 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.serverwahl-slider-storyfortschrit4 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.serverwahl-slider-passiv20 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-passiv21 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv22 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv23 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-activ4 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-primary-gruen);
}
.serverwahl-slider-passiv24 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-larrow4 {
  flex: 0 0 auto;
  left: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-rarrow4 {
  flex: 0 0 auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-container23 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.serverwahl-slider-container24 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-container25 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-image6 {
  width: 100%;
  height: 100%;
}
.serverwahl-slider-text11 {
  width: auto;
  margin-top: var(--dl-space-space-unit);
}
.serverwahl-slider-container26 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.serverwahl-slider-storyfortschrit5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.serverwahl-slider-passiv25 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-passiv26 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv27 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv28 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-passiv29 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.serverwahl-slider-activ5 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-gruen);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.serverwahl-slider-larrow5 {
  flex: 0 0 auto;
  left: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-rarrow5 {
  flex: 0 0 auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.serverwahl-slider-container27 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.serverwahl-slider-container28 {
  width: 100%;
  display: flex;
  margin-top: 0px;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-container29 {
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-image7 {
  width: 100%;
  height: 100%;
}
.serverwahl-slider-text13 {
  width: auto;
  margin-top: var(--dl-space-space-unit);
}
.serverwahl-slider-container30 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-container31 {
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.serverwahl-slider-image8 {
  width: 100%;
  height: 100%;
}
.serverwahl-slider-text14 {
  width: auto;
  margin-top: var(--dl-space-space-unit);
}
.serverwahl-slider-container33 {
  display: contents;
}




.serverwahl-slider-root-class-name4 {
  top: 0px;
}
@media(max-width: 991px) {
  .serverwahl-slider-larrow {
    bottom: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-rarrow {
    bottom: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-larrow1 {
    bottom: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-rarrow1 {
    bottom: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-larrow2 {
    bottom: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-rarrow2 {
    bottom: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-larrow3 {
    bottom: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-rarrow3 {
    bottom: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-larrow4 {
    bottom: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-rarrow4 {
    bottom: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-larrow5 {
    bottom: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-rarrow5 {
    bottom: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 480px) {
  .serverwahl-slider-text {
    margin-bottom: 0px;
  }
  .serverwahl-slider-container03 {
    height: auto;
    justify-content: flex-start;
  }
  .serverwahl-slider-container04 {
    flex-direction: row;
  }
  .serverwahl-slider-text01 {
    margin-top: 0px;
    margin-left: var(--dl-space-space-unit);
  }
  .serverwahl-slider-text02 {
    margin-top: 0px;
    margin-left: var(--dl-space-space-unit);
  }
  .serverwahl-slider-text03 {
    margin-top: 0px;
    margin-left: var(--dl-space-space-unit);
  }
  .serverwahl-slider-container11 {
    width: 100%;
    height: auto;
    justify-content: flex-start;
  }
  .serverwahl-slider-container12 {
    flex-direction: column;
  }
  .serverwahl-slider-text05 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .serverwahl-slider-container15 {
    width: 100%;
    height: auto;
    justify-content: flex-start;
  }
  .serverwahl-slider-container16 {
    flex-direction: column;
  }
  .serverwahl-slider-text07 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .serverwahl-slider-container19 {
    width: 100%;
    height: auto;
    justify-content: flex-start;
  }
  .serverwahl-slider-container20 {
    flex-direction: column;
  }
  .serverwahl-slider-text09 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .serverwahl-slider-container23 {
    width: 100%;
    height: auto;
    justify-content: flex-start;
  }
  .serverwahl-slider-container24 {
    flex-direction: column;
  }
  .serverwahl-slider-text11 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .serverwahl-slider-container27 {
    width: 100%;
    height: auto;
    justify-content: flex-start;
  }
  .serverwahl-slider-container28 {
    flex-direction: column;
  }
  .serverwahl-slider-text13 {
    margin-left: 0px;
  }
  .serverwahl-slider-container30 {
    flex-direction: column;
  }
  .serverwahl-slider-text14 {
    margin-left: 0px;
  }
}
@media(max-width: 360px) {
  .serverwahl-slider-container03 {
    height: auto;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .serverwahl-slider-container05 {
    width: 50px;
    height: 50px;
  }
  .serverwahl-slider-text01 {
    margin-top: 0px;
  }
  .serverwahl-slider-container07 {
    width: 50px;
    height: 50px;
  }
  .serverwahl-slider-text02 {
    margin-top: 0px;
  }
  .serverwahl-slider-container09 {
    width: 50px;
    height: 50px;
  }
  .serverwahl-slider-text03 {
    margin-top: 0px;
  }
  .serverwahl-slider-container11 {
    width: 100%;
    height: auto;
  }
  .serverwahl-slider-container13 {
    width: 50px;
    height: 50px;
  }
  .serverwahl-slider-container15 {
    width: 100%;
    height: auto;
  }
  .serverwahl-slider-container17 {
    width: 50px;
    height: 50px;
  }
  .serverwahl-slider-container19 {
    width: 100%;
    height: auto;
  }
  .serverwahl-slider-container21 {
    width: 50px;
    height: auto;
  }
  .serverwahl-slider-container23 {
    width: 100%;
    height: auto;
  }
  .serverwahl-slider-container25 {
    width: 50px;
    height: auto;
  }
  .serverwahl-slider-container27 {
    width: 100%;
    height: auto;
  }
  .serverwahl-slider-container29 {
    width: 200px;
    height: 25px;
  }
  .serverwahl-slider-container31 {
    width: 200px;
    height: 25px;
  }
}
