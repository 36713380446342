.skalierung-slider-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 100;
  position: absolute;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  background-color: var(--dl-color-primary-bg);
}
.skalierung-slider-container1 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
}
.skalierung-slider-sliderpanel {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.skalierung-slider-container2 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.skalierung-slider-storyfortschrit {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 1fr ;
}
.skalierung-slider-activ {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius22);
  flex-direction: column;
  background-color: var(--dl-color-primary-gruen);
}
.skalierung-slider-container3 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.skalierung-slider-container4 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.skalierung-slider-container5 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.skalierung-slider-image {
  width: 100%;
  height: 100%;
}
.skalierung-slider-text1 {
  width: auto;
  margin-top: var(--dl-space-space-threeunits);
}
.skalierung-slider-container7 {
  display: contents;
}




@media(max-width: 480px) {
  .skalierung-slider-container3 {
    width: 100%;
    height: auto;
    justify-content: flex-start;
  }
  .skalierung-slider-container4 {
    flex-direction: column;
  }
  .skalierung-slider-text1 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}
@media(max-width: 360px) {
  .skalierung-slider-container3 {
    width: 100%;
    height: auto;
  }
  .skalierung-slider-container5 {
    width: 50px;
    height: 50px;
  }
}
