.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #151914;
}
.home-content {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-navlink {
  display: contents;
}
.home-analyse {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-text {
  color: var(--dl-color-gray-metal);
  font-size: 30px;
  font-family: "Nunitot-Light";
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-image {
  width: 200px;
  object-fit: cover;
}
.home-navlink1 {
  display: contents;
}
.home-dateien {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-text1 {
  font-size: 30px;
  font-family: "Nunitot-Light";
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-navlink2 {
  display: contents;
}
.home-hosting {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-text2 {
  font-size: 30px;
  font-family: "Nunitot-Light";
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-legal {
  flex: 0 0 auto;
  width: 90%;
  bottom: 0px;
  height: 54px;
  display: flex;
  padding: 1.5px;
  position: fixed;
  max-width: 1200px;
  align-items: center;
  padding-top: 1px;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  padding-left: 1px;
  border-radius: 24px;
  margin-bottom: 5px;
  padding-right: 1px;
  flex-direction: row;
  padding-bottom: 1px;
  justify-content: space-between;
}
.home-navlink3 {
  display: contents;
}
.home-container1 {
  flex: 0 0 auto;
  width: 40%;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 0px;
  border-color: var(--dl-color-primary-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius22);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-text3 {
  font-size: 20px;
  font-family: "Nunitot-Light";
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-navlink4 {
  display: contents;
}
.home-container2 {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-text4 {
  font-size: 20px;
}
.home-navlink5 {
  display: contents;
}
.home-container3 {
  flex: 0 0 auto;
  width: 40%;
  height: 50px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius22);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-text5 {
  font-size: 20px;
  font-family: "Nunitot-Light";
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
@media(max-width: 480px) {
  .home-text {
    font-size: 25px;
  }
  .home-text1 {
    font-size: 25px;
  }
  .home-text2 {
    font-size: 25px;
  }
  .home-legal {
    margin-bottom: 3px;
  }
  .home-container2 {
    width: 45px;
    height: 45px;
  }
}
@media(max-width: 360px) {
  .home-image {
    width: 150px;
  }
  .home-legal {
    margin-bottom: 3px;
  }
  .home-container2 {
    width: 40px;
    height: 40px;
  }
}
