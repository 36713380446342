.analyse-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #151914;
}
.analyse-navlink {
  display: contents;
}
.analyse-component {
  text-decoration: none;
}
.analyse-content {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  margin-top: 75px;
  align-items: center;
  margin-bottom: 75px;
  flex-direction: column;
  justify-content: center;
}
.analyse-component1 {
  text-decoration: none;
}
.analyse-container01 {
  width: 90%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.analyse-navlink1 {
  display: contents;
}
.analyse-punkt {
  width: 100%;
  display: none;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.analyse-titel {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 10px;
}
.analyse-text {
  font-size: 15px;
  font-family: "Nunitot-Light";
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.analyse-lvl {
  align-self: flex-start;
}
.analyse-navlink2 {
  display: contents;
}
.analyse-punkt1 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.analyse-titel1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 10px;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.analyse-text01 {
  line-height: 1.15;
}
.analyse-wertung {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  margin-top: -1px;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.analyse-container02 {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.analyse-container03 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.analyse-image {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text02 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.analyse-container04 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.analyse-image01 {
  width: auto;
  height: 30px;
  object-fit: cover;
}
.analyse-container05 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.analyse-image02 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text04 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.analyse-container06 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.analyse-image03 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text05 {
  margin-left: var(--dl-space-space-unit);
}
.analyse-navlink3 {
  display: contents;
}
.analyse-serverwahl {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.analyse-titel2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 10px;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.analyse-text06 {
  line-height: 1.15;
}
.analyse-container07 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  margin-top: -1px;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.analyse-container08 {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.analyse-container09 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.analyse-image04 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text07 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.analyse-container10 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.analyse-image05 {
  width: auto;
  height: 30px;
  object-fit: cover;
}
.analyse-container11 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.analyse-image06 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text09 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.analyse-container12 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.analyse-image07 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text10 {
  margin-left: var(--dl-space-space-unit);
}
.analyse-navlink4 {
  display: contents;
}
.analyse-serverwahl1 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.analyse-titel3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 10px;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.analyse-text11 {
  line-height: 1.15;
}
.analyse-container13 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  margin-top: -1px;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.analyse-container14 {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.analyse-container15 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.analyse-image08 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text14 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.analyse-container16 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.analyse-image09 {
  width: auto;
  height: 30px;
  object-fit: cover;
}
.analyse-container17 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.analyse-image10 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text16 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.analyse-container18 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.analyse-image11 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text17 {
  margin-left: var(--dl-space-space-unit);
}
.analyse-navlink5 {
  display: contents;
}
.analyse-serverwahl2 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.analyse-titel4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 10px;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.analyse-text18 {
  line-height: 1.15;
}
.analyse-container19 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  margin-top: -1px;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.analyse-container20 {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.analyse-container21 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.analyse-image12 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text21 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.analyse-container22 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.analyse-image13 {
  width: auto;
  height: 30px;
  object-fit: cover;
}
.analyse-container23 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.analyse-image14 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text23 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.analyse-container24 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.analyse-image15 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text24 {
  margin-left: var(--dl-space-space-unit);
}
.analyse-container25 {
  width: 90%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.analyse-lvl1 {
  align-self: flex-start;
}
.analyse-navlink6 {
  display: contents;
}
.analyse-punkt2 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.analyse-titel5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 10px;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.analyse-text25 {
  line-height: 1.15;
}
.analyse-wertung1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  margin-top: -1px;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.analyse-container26 {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.analyse-container27 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.analyse-image16 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text26 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.analyse-container28 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.analyse-image17 {
  width: auto;
  height: 30px;
  object-fit: cover;
}
.analyse-container29 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.analyse-image18 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text28 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.analyse-container30 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.analyse-image19 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.analyse-text29 {
  margin-left: var(--dl-space-space-unit);
}
.analyse-container32 {
  display: contents;
}
@media(max-width: 991px) {
  .analyse-container02 {
    width: 70%;
  }
  .analyse-container08 {
    width: 70%;
  }
  .analyse-container14 {
    width: 70%;
  }
  .analyse-container20 {
    width: 70%;
  }
  .analyse-container26 {
    width: 70%;
  }
}
@media(max-width: 480px) {
  .analyse-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .analyse-punkt {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .analyse-titel {
    display: none;
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .analyse-punkt1 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .analyse-titel1 {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .analyse-wertung {
    flex-direction: column;
  }
  .analyse-container02 {
    width: 100%;
  }
  .analyse-image {
    height: 20px;
  }
  .analyse-container04 {
    display: flex;
    flex-direction: column;
  }
  .analyse-image01 {
    height: 20px;
    opacity: 0.4;
  }
  .analyse-text03 {
    font-size: 10px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .analyse-image02 {
    height: 20px;
  }
  .analyse-text04 {
    align-self: flex-end;
  }
  .analyse-container06 {
    display: none;
  }
  .analyse-serverwahl {
    margin-top: var(--dl-space-space-halfunit);
  }
  .analyse-titel2 {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .analyse-container07 {
    flex-direction: column;
  }
  .analyse-container08 {
    width: 100%;
  }
  .analyse-image04 {
    height: 20px;
  }
  .analyse-container10 {
    display: flex;
    flex-direction: column;
  }
  .analyse-image05 {
    height: 20px;
    opacity: 0.4;
  }
  .analyse-text08 {
    font-size: 10px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .analyse-image06 {
    height: 20px;
  }
  .analyse-text09 {
    align-self: flex-end;
  }
  .analyse-container12 {
    display: none;
  }
  .analyse-serverwahl1 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .analyse-titel3 {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .analyse-container13 {
    flex-direction: column;
  }
  .analyse-container14 {
    width: 100%;
  }
  .analyse-image08 {
    height: 20px;
  }
  .analyse-container16 {
    display: flex;
    flex-direction: column;
  }
  .analyse-image09 {
    height: 20px;
    opacity: 0.4;
  }
  .analyse-text15 {
    font-size: 10px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .analyse-image10 {
    height: 20px;
  }
  .analyse-text16 {
    align-self: flex-end;
  }
  .analyse-container18 {
    display: none;
  }
  .analyse-serverwahl2 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .analyse-titel4 {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .analyse-container19 {
    flex-direction: column;
  }
  .analyse-container20 {
    width: 100%;
  }
  .analyse-image12 {
    height: 20px;
  }
  .analyse-container22 {
    display: flex;
    flex-direction: column;
  }
  .analyse-image13 {
    height: 20px;
    opacity: 0.4;
  }
  .analyse-text22 {
    font-size: 10px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .analyse-image14 {
    height: 20px;
  }
  .analyse-text23 {
    align-self: flex-end;
  }
  .analyse-container24 {
    display: none;
  }
  .analyse-container25 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .analyse-punkt2 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .analyse-titel5 {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .analyse-wertung1 {
    flex-direction: column;
  }
  .analyse-container26 {
    width: 100%;
  }
  .analyse-image16 {
    height: 20px;
  }
  .analyse-container28 {
    display: flex;
    flex-direction: column;
  }
  .analyse-image17 {
    height: 20px;
    opacity: 0.4;
  }
  .analyse-text27 {
    font-size: 10px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .analyse-image18 {
    height: 20px;
  }
  .analyse-text28 {
    align-self: flex-end;
  }
  .analyse-container30 {
    display: none;
  }
}
@media(max-width: 360px) {
  .analyse-content {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .analyse-punkt {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .analyse-punkt1 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .analyse-wertung {
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .analyse-container02 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .analyse-image {
    height: 18px;
  }
  .analyse-container04 {
    top: 0px;
    right: 14px;
    bottom: 0px;
    margin: auto;
    flex-direction: column;
  }
  .analyse-image02 {
    height: 18px;
  }
  .analyse-serverwahl {
    margin-top: var(--dl-space-space-halfunit);
  }
  .analyse-container07 {
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .analyse-container08 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .analyse-image04 {
    height: 18px;
  }
  .analyse-container10 {
    top: 7px;
    right: 14px;
    flex-direction: column;
  }
  .analyse-image06 {
    height: 18px;
  }
  .analyse-serverwahl1 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .analyse-container13 {
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .analyse-container14 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .analyse-image08 {
    height: 18px;
  }
  .analyse-container16 {
    top: 7px;
    right: 14px;
    flex-direction: column;
  }
  .analyse-image10 {
    height: 18px;
  }
  .analyse-serverwahl2 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .analyse-container19 {
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .analyse-container20 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .analyse-image12 {
    height: 18px;
  }
  .analyse-container22 {
    top: 7px;
    right: 14px;
    flex-direction: column;
  }
  .analyse-image14 {
    height: 18px;
  }
  .analyse-punkt2 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .analyse-wertung1 {
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .analyse-container26 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .analyse-image16 {
    height: 18px;
  }
  .analyse-container28 {
    top: 0px;
    right: 14px;
    bottom: 0px;
    margin: auto;
    flex-direction: column;
  }
  .analyse-image18 {
    height: 18px;
  }
}
