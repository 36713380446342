.hosting-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #151914;
}
.hosting-component {
  text-decoration: none;
}
.hosting-navlink {
  display: contents;
}
.hosting-component1 {
  text-decoration: none;
}
.hosting-content {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  margin-top: 75px;
  align-items: center;
  margin-bottom: 75px;
  flex-direction: column;
  justify-content: center;
}
.hosting-container01 {
  width: 90%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.hosting-lvl {
  align-self: flex-start;
}
.hosting-navlink1 {
  display: contents;
}
.hosting-strom {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.hosting-titel {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: 12px;
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 12px;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.hosting-text {
  line-height: 1.15;
}
.hosting-container02 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  margin-top: -1px;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.hosting-container03 {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.hosting-container04 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.hosting-image {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text01 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  line-height: 1;
  margin-bottom: var(--dl-space-space-halfunit);
}
.hosting-container05 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hosting-image01 {
  width: auto;
  height: 30px;
  object-fit: cover;
}
.hosting-container06 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hosting-image02 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text03 {
  margin-left: var(--dl-space-space-unit);
}
.hosting-navlink2 {
  display: contents;
}
.hosting-serverwahl {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.hosting-titel1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 10px;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.hosting-text04 {
  line-height: 1.15;
}
.hosting-container07 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  margin-top: -1px;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.hosting-container08 {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.hosting-container09 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.hosting-image03 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text05 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.hosting-container10 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hosting-image04 {
  width: auto;
  height: 30px;
  object-fit: cover;
}
.hosting-container11 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hosting-image05 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text07 {
  margin-left: var(--dl-space-space-unit);
}
.hosting-container12 {
  width: 90%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.hosting-lvl1 {
  align-self: flex-start;
}
.hosting-navlink3 {
  display: contents;
}
.hosting-punkt {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.hosting-titel2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 10px;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.hosting-text08 {
  line-height: 1.15;
}
.hosting-wertung {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  margin-top: -1px;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.hosting-container13 {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.hosting-container14 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.hosting-image06 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text09 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.hosting-container15 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hosting-image07 {
  width: auto;
  height: 30px;
  object-fit: cover;
}
.hosting-container16 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.hosting-image08 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text11 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.hosting-container17 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hosting-image09 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text12 {
  margin-left: var(--dl-space-space-unit);
}
.hosting-navlink4 {
  display: contents;
}
.hosting-serverwahl1 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.hosting-titel3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 10px;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.hosting-text13 {
  line-height: 1.15;
}
.hosting-container18 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  margin-top: -1px;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.hosting-container19 {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.hosting-container20 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.hosting-image10 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text14 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.hosting-container21 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hosting-image11 {
  width: auto;
  height: 30px;
  object-fit: cover;
}
.hosting-container22 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.hosting-image12 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text16 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.hosting-container23 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hosting-image13 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text17 {
  margin-left: var(--dl-space-space-unit);
}
.hosting-container24 {
  width: 90%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.hosting-lvl2 {
  align-self: flex-start;
}
.hosting-navlink5 {
  display: contents;
}
.hosting-strom1 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.hosting-titel4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-primary-gruen);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius22);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 10px;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.hosting-text18 {
  line-height: 1.15;
}
.hosting-container25 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  margin-top: -1px;
  align-items: center;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.hosting-container26 {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.hosting-container27 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.hosting-image14 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text19 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.hosting-container28 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hosting-image15 {
  width: auto;
  height: 30px;
  object-fit: cover;
}
.hosting-container29 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.hosting-image16 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text21 {
  color: var(--dl-color-gray-metal);
  z-index: 100;
  align-self: flex-start;
  text-align: left;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
}
.hosting-container30 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hosting-image17 {
  width: auto;
  height: 30px;
  opacity: 0.4;
  object-fit: cover;
}
.hosting-text22 {
  margin-left: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .hosting-container03 {
    width: 70%;
  }
  .hosting-container08 {
    width: 70%;
  }
  .hosting-container13 {
    width: 70%;
  }
  .hosting-container19 {
    width: 70%;
  }
  .hosting-container26 {
    width: 70%;
  }
}
@media(max-width: 480px) {
  .hosting-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .hosting-strom {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .hosting-titel {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .hosting-container02 {
    flex-direction: column;
  }
  .hosting-container03 {
    width: 100%;
  }
  .hosting-image {
    height: 20px;
  }
  .hosting-container05 {
    display: flex;
    flex-direction: column;
  }
  .hosting-image01 {
    height: 20px;
    opacity: 0.4;
    background-size: cover;
    background-image: url("/Icons/uhr.svg");
  }
  .hosting-text02 {
    font-size: 10px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .hosting-container06 {
    display: none;
  }
  .hosting-serverwahl {
    margin-top: var(--dl-space-space-halfunit);
  }
  .hosting-titel1 {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .hosting-container07 {
    flex-direction: column;
  }
  .hosting-container08 {
    width: 100%;
  }
  .hosting-image03 {
    height: 20px;
  }
  .hosting-container10 {
    display: flex;
    flex-direction: column;
  }
  .hosting-image04 {
    height: 20px;
    opacity: 0.4;
  }
  .hosting-text06 {
    font-size: 10px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .hosting-container11 {
    display: none;
  }
  .hosting-container12 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .hosting-punkt {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .hosting-titel2 {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .hosting-wertung {
    flex-direction: column;
  }
  .hosting-container13 {
    width: 100%;
  }
  .hosting-image06 {
    height: 20px;
  }
  .hosting-container15 {
    display: flex;
    flex-direction: column;
  }
  .hosting-image07 {
    height: 20px;
    opacity: 0.4;
  }
  .hosting-text10 {
    font-size: 10px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .hosting-image08 {
    height: 20px;
  }
  .hosting-text11 {
    align-self: flex-end;
  }
  .hosting-container17 {
    display: none;
  }
  .hosting-serverwahl1 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .hosting-titel3 {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .hosting-container18 {
    flex-direction: column;
  }
  .hosting-container19 {
    width: 100%;
  }
  .hosting-image10 {
    height: 20px;
  }
  .hosting-container21 {
    display: flex;
    flex-direction: column;
  }
  .hosting-image11 {
    height: 20px;
    opacity: 0.4;
  }
  .hosting-text15 {
    font-size: 10px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .hosting-image12 {
    height: 20px;
  }
  .hosting-text16 {
    align-self: flex-end;
  }
  .hosting-container23 {
    display: none;
  }
  .hosting-container24 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .hosting-strom1 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .hosting-titel4 {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .hosting-container25 {
    flex-direction: column;
  }
  .hosting-container26 {
    width: 100%;
  }
  .hosting-image14 {
    height: 20px;
  }
  .hosting-container28 {
    display: flex;
    flex-direction: column;
  }
  .hosting-image15 {
    height: 20px;
    opacity: 0.4;
  }
  .hosting-text20 {
    font-size: 10px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .hosting-image16 {
    height: 20px;
  }
  .hosting-text21 {
    align-self: flex-end;
  }
  .hosting-container30 {
    display: none;
  }
}
@media(max-width: 360px) {
  .hosting-content {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .hosting-strom {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .hosting-titel {
    z-index: 200;
  }
  .hosting-container02 {
    z-index: 100;
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: -1px;
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .hosting-container03 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .hosting-image {
    height: 18px;
  }
  .hosting-container05 {
    flex-direction: column;
  }
  .hosting-serverwahl {
    margin-top: var(--dl-space-space-halfunit);
  }
  .hosting-container07 {
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .hosting-container08 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .hosting-image03 {
    height: 18px;
  }
  .hosting-container10 {
    top: 7px;
    right: 14px;
    flex-direction: column;
  }
  .hosting-punkt {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .hosting-wertung {
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .hosting-container13 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .hosting-image06 {
    height: 18px;
  }
  .hosting-container15 {
    top: 0px;
    right: 14px;
    bottom: 0px;
    margin: auto;
    flex-direction: column;
  }
  .hosting-image08 {
    height: 18px;
  }
  .hosting-serverwahl1 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .hosting-container18 {
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .hosting-container19 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .hosting-image10 {
    height: 18px;
  }
  .hosting-container21 {
    top: 7px;
    right: 14px;
    flex-direction: column;
  }
  .hosting-image12 {
    height: 18px;
  }
  .hosting-strom1 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .hosting-container25 {
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .hosting-container26 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .hosting-image14 {
    height: 18px;
  }
  .hosting-container28 {
    top: 0px;
    right: 14px;
    bottom: 0px;
    margin: auto;
    flex-direction: column;
  }
  .hosting-image16 {
    height: 18px;
  }
}
