.impressum-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  overflow: auto;
  flex-wrap: wrap;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-bg);
}
.impressum-text {
  color: var(--dl-color-primary-gruen);
  font-size: 15px;
  margin-top: var(--dl-space-space-threeunits);
  text-align: left;
  font-family: "Courier New";
  line-height: 0.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.impressum-container1 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.impressum-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.impressum-text17 {
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
}
.impressum-text18 {
  color: var(--dl-color-gray-metal);
  padding: var(--dl-space-space-unit);
}
.impressum-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.impressum-text43 {
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
}
.impressum-text44 {
  color: var(--dl-color-gray-metal);
  padding: var(--dl-space-space-unit);
  text-align: start;
}
.impressum-navlink {
  display: contents;
}
.impressum-component1 {
  text-decoration: none;
}
@media(max-width: 991px) {
  .impressum-container {
    padding: var(--dl-space-space-twounits);
  }
}
@media(max-width: 480px) {
  .impressum-text {
    display: none;
    font-size: 7px;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .impressum-container1 {
    justify-content: flex-start;
  }
}
@media(max-width: 479px) {
  .impressum-text18 {
    padding-left: 10vw;
  }
  .impressum-text44 {
    padding-left: 10vw;
  }
  .impressum-text45 {
    padding-left: 10vw;
  }
  .impressum-text46 {
    padding-left: 10vw;
  }
  .impressum-text47 {
    padding-left: 10vw;
  }
  .impressum-text48 {
    padding-left: 10vw;
  }
  .impressum-text49 {
    padding-left: 10vw;
  }
  .impressum-text50 {
    padding-left: 10vw;
  }
  .impressum-text51 {
    padding-left: 10vw;
  }
  .impressum-text52 {
    padding-left: 10vw;
  }
}
@media(max-width: 360px) {
  .impressum-container {
    padding: var(--dl-space-space-twounits);
  }
  .impressum-container1 {
    justify-content: flex-start;
  }
}
