.a-template-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #151914;
}
.a-template-component {
  text-decoration: none;
}
.a-template-text {
  color: var(--dl-color-primary-gruen);
  font-size: 5px;
  font-family: monospace;
  line-height: 0.5;
  white-space: pre;
}
