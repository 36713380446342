@font-face {
  font-family: "UbuntuMono";
  font-style: normal;
  font-format: woff2;
  font-weight: 400;
  src: url("/fonts/ubuntu-mono-v17-latin-regular.woff2") ;
}
@font-face {
  font-family: "Nunitot-Light";
  font-style: normal;
  font-format: woff2;
  font-weight: 300;
  src: url("/fonts/nunito-v26-latin-300.woff2") ;
}
:root {
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-gray-metal: #a9bea3ff;
  --dl-color-gray-white: #FFFFFF;
  --dl-color-primary-bg: #151914ff;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-color-primary-gruen: #53e62eff;
  --dl-color-primary-metal: #a9bea3ff;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-primary-tppurple: #854af6ff;
  --dl-radius-radius-radius22: 22px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}
.button {
  color: var(--dl-color-gray-metal);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-metal);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-metal);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-metal);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.finger {
  cursor: pointer;
}
.text {
  font-size: 1.5em;
}
.headline {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-unit);
}

.Content {
  font-size: 1rem;
  font-family: "Nunitot-Light";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.Rubrik {
  font-size: 22px;
  font-family: "Nunitot-Light";
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: none;
  text-decoration: none;
}
.HAL {
  font-size: 20px;
  font-family: "UbuntuMono";
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 33px;
  font-family: "Nunitot-Light";
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.minitext {
  font-size: 10px;
  font-family: "Nunitot-Light";
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-decoration: none;
}
@media(max-width: 991px) {
  .text {
    font-size: 1.5em;
  }
}
@media(max-width: 480px) {
  .text {
    font-size: 1em;
  }
  .headline {
    font-size: 2rem;
  }
}
