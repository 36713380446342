.dateiformate-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #151914;
}
.dateiformate-navlink {
  display: contents;
}
.dateiformate-component {
  text-decoration: none;
}
