.a-headline-headline {
  top: -1px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 75px;
  margin: auto;
  display: flex;
  z-index: 101;
  position: fixed;
  max-width: 1200px;
  margin-top: -1px;
  align-items: center;
  border-color: var(--dl-color-primary-gruen);
  border-style: solid;
  border-width: 1px;
  border-radius: 50px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-bg);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.a-headline-text {
  color: var(--dl-color-primary-gruen);
  font-size: 5px;
  font-family: monospace;
  line-height: 0.5;
  white-space: pre;
  font-kerning: normal;
}










@media(max-width: 480px) {
  .a-headline-headline {
    height: 50px;
  }
}
@media(max-width: 360px) {
  .a-headline-headline {
    height: 50px;
    z-index: 400;
  }
}
