.beacon-slider-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 100;
  position: absolute;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  background-color: var(--dl-color-primary-bg);
}
.beacon-slider-container01 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
}
.beacon-slider-sliderpanel {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.beacon-slider-container02 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.beacon-slider-storyfortschrit {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
}
.beacon-slider-activ {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-gruen);
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
}
.beacon-slider-passiv {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.beacon-slider-larrow {
  flex: 0 0 auto;
  left: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.beacon-slider-rarrow {
  flex: 0 0 auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.beacon-slider-lottie-node {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  display: none;
  align-self: center;
}
.beacon-slider-container03 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.beacon-slider-container04 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.beacon-slider-text1 {
  width: auto;
  margin-top: var(--dl-space-space-threeunits);
}
.beacon-slider-container05 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.beacon-slider-storyfortschrit1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
}
.beacon-slider-passiv1 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  border-top-left-radius: var(--dl-radius-radius-radius22);
  border-bottom-left-radius: var(--dl-radius-radius-radius22);
}
.beacon-slider-activ1 {
  flex: 0 0 auto;
  width: auto;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-metal);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-gruen);
  border-top-right-radius: var(--dl-radius-radius-radius22);
  border-bottom-right-radius: var(--dl-radius-radius-radius22);
}
.beacon-slider-larrow1 {
  flex: 0 0 auto;
  left: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.beacon-slider-rarrow1 {
  flex: 0 0 auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 50%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.beacon-slider-container06 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.beacon-slider-container07 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.beacon-slider-container08 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.beacon-slider-video {
  width: 900px;
  height: auto;
  max-height: 50vh;
}
.beacon-slider-text3 {
  width: auto;
  margin-top: var(--dl-space-space-threeunits);
}
.beacon-slider-container10 {
  display: contents;
}




@media(max-width: 991px) {
  .beacon-slider-larrow {
    bottom: var(--dl-space-space-halfunit);
  }
  .beacon-slider-rarrow {
    bottom: var(--dl-space-space-halfunit);
  }
  .beacon-slider-larrow1 {
    bottom: var(--dl-space-space-halfunit);
  }
  .beacon-slider-rarrow1 {
    bottom: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 480px) {
  .beacon-slider-container03 {
    height: auto;
    justify-content: flex-start;
  }
  .beacon-slider-container04 {
    flex-direction: column;
  }
  .beacon-slider-text1 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .beacon-slider-container06 {
    height: auto;
    justify-content: flex-start;
  }
  .beacon-slider-container07 {
    flex-direction: column;
  }
  .beacon-slider-video {
    width: 90vw;
  }
  .beacon-slider-text3 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}
@media(max-width: 360px) {
  .beacon-slider-container03 {
    width: 100%;
    height: auto;
  }
  .beacon-slider-container06 {
    width: 100%;
    height: auto;
  }
  .beacon-slider-container08 {
    width: 9px;
  }
}
