.firefox-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #151914;
}
.firefox-navlink {
  display: contents;
}
.firefox-component {
  text-decoration: none;
}
.firefox-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.firefox-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.firefox-text {
  width: auto;
  margin-top: var(--dl-space-space-threeunits);
}
@media(max-width: 480px) {
  .firefox-container1 {
    height: auto;
    justify-content: flex-start;
  }
  .firefox-container2 {
    flex-direction: column;
  }
  .firefox-text {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}
@media(max-width: 360px) {
  .firefox-container1 {
    height: auto;
  }
}
